<template>
  <div>
    <div class="" style="width: 100%">
      <img style="display: block; width: 1920px;margin: auto;" src="../../assets/images/djt/djt.jpg" alt="">
    </div>
    <div class="bg">
      <div class="wrapper">
        <div>
          <div _ngcontent-c4="" class="bigclass-box">
  
  <div _ngcontent-c4="" class="content">
    <p _ngcontent-c4="" class="intro">
      大讲堂依托于直播技术，不定期组织政策文件解读、技术交流、专题研讨等直播活动，让更多人可以与交通运输行业、网络安全行业领导专家进行“面对面”交流，了解学习新政策、新技术、新知识。也可以向特定群体提供定向直播，在方便学习的同时满足内部学习的保密性。
    </p>
    <p _ngcontent-c4="" class="intro">大讲堂专题内容:</p>
    <ul _ngcontent-c4="" class="djt-list">
      <li _ngcontent-c4="">
        <div _ngcontent-c4="" class="img">
          <img _ngcontent-c4="" alt="" src="../../assets/images/djt/1.png">
          <strong _ngcontent-c4="">政策解读</strong>
        </div>
        <p _ngcontent-c4="">邀请行业领导、资深专家对政策法规等文件进行详细解读，了解文件意义，掌握执行方法，促进政策文件落地。</p>
      </li>
      <li _ngcontent-c4="">
        <div _ngcontent-c4="" class="img">
          <img _ngcontent-c4="" alt="" src="../../assets/images/djt/2.png">
          <strong _ngcontent-c4="">重要会议</strong>
        </div>
        <p _ngcontent-c4="">重要会议加密直播，可仅向定向用户开放，方便多地点参会需求的同时保障会议严肃性、私密性。</p>
      </li>
      <li _ngcontent-c4="">
        <div _ngcontent-c4="" class="img">
          <img _ngcontent-c4="" alt="" src="../../assets/images/djt/3.png">
          <strong _ngcontent-c4="">研讨交流</strong>
        </div>
        <p _ngcontent-c4="">不定期组织行业、技术专题研讨会议，在线观摩行业专家交流，远程参与讨论。</p>
      </li>
      <li _ngcontent-c4="">
        <div _ngcontent-c4="" class="img">
          <img _ngcontent-c4="" alt="" src="../../assets/images/djt/4.png">
          <strong _ngcontent-c4="">技术宣讲</strong>
        </div>
        <p _ngcontent-c4="">邀请行业内具有影响力企业进行新技术、新理念、新案例宣讲，掌握技术前沿动向，拓展眼界启迪思路。</p>
      </li>
      <li _ngcontent-c4="">
        <div _ngcontent-c4="" class="img">
          <img _ngcontent-c4="" alt="" src="../../assets/images/djt/5.png">
          <strong _ngcontent-c4="">攻防比赛</strong>
        </div>
        <p _ngcontent-c4="">攻防赛事直播，沉浸式感受比赛气氛。</p>
      </li>
    </ul>
  </div>
</div>
          </div>
      </div>
    </div>
  </div>

  
</template>

<style lang="less" scoped>
  .bigclass-box[_ngcontent-c4]   .content[_ngcontent-c4]   .djt-list[_ngcontent-c4]{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bigclass-box[_ngcontent-c4]   .content[_ngcontent-c4]   .djt-list[_ngcontent-c4]   li[_ngcontent-c4]{
  width: 47%;
  display: flex;
  padding: 40px;
  align-items: center;
}
.bigclass-box[_ngcontent-c4]   .content[_ngcontent-c4]   .djt-list[_ngcontent-c4]   li[_ngcontent-c4]   .img[_ngcontent-c4]{
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.bigclass-box[_ngcontent-c4]   .content[_ngcontent-c4]   .djt-list[_ngcontent-c4]   li[_ngcontent-c4]   .img[_ngcontent-c4]   strong[_ngcontent-c4]{
  font-size: 20px;
  margin-top: 5px;
}
.bigclass-box[_ngcontent-c4]   .content[_ngcontent-c4]   .djt-list[_ngcontent-c4]   li[_ngcontent-c4]   img[_ngcontent-c4]{
  width: 100px;
  height: 100px;
  margin: 0;
  padding: 0;

}
.bigclass-box[_ngcontent-c4]   .content[_ngcontent-c4]   .djt-list[_ngcontent-c4]   li[_ngcontent-c4]   p[_ngcontent-c4]{
  line-height: 30px;
  text-align: left;
}
  .bold {
    font-weight: bold;
  }
  .left {
    margin: 20px 0;
    font-size: 22px;
  }
  .em2{
  text-indent: 2em;
  line-height: 40px;
}
.center{
  text-align: center;
}
// .img {
//   width: 100%;
//   display: flex;
//   padding: 10px 0;
//   align-items: center;
//   justify-content: center;
// }
// .img img {
//   width: 600px;
// }
  .bg {
    color: #333;
    font-size: 16px;
    background: #F7F6FA;
    padding: 60px 0;
    .activity {
      background: #fff;
      padding: 20px;
    }
  }
  .text-title {
    font-size: 24px;
    font-weight: bold;
    color:#333;
    text-align: center;
    margin-bottom: 18px;

  }
  .title {
    margin-right: 7px;
    font-size: 18px;
    color: #217aff;
    h6 {
      font-size: 22px;
  margin-right: 7px;

    }
  }
  .intro {
    text-indent: 2em;
  line-height: 40px;
  font-size: 16px;
  color: #333;

  }
  
  
  
  </style>
